<div class="dialog-container__inner-container">
	<ng-container *ngIf="canDelete">
		<div class="dialog-container__text-container">
			<div class="dialog-container__title">
				<span>Are you sure you want to delete the service?</span>
				<mat-icon (click)='onCloseClick()'>close</mat-icon>
			</div>
			<span class="dialog-container__description">It will be deleted permanently.</span>
		</div>
	</ng-container>
	<ng-container *ngIf="!canDelete">
		<div class="dialog-container__text-container">
			<div class="dialog-container__title">
				<span>The service can’t be deleted</span>
				<mat-icon (click)='onCloseClick()'>close</mat-icon>
			</div>
			<span class="dialog-container__description">To delete the service you should unassign it from the building first.</span>
		</div>
	</ng-container>
	<div class="dialog-container__buttons">
		<div (click)="onCloseClick()" class="delete-button-item secondary">
			Cancel
		</div>
		<div (click)="onDeleteClick()" class="delete-button-item primary">
			Delete
		</div>
	</div>
</div>
