import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Pipe({
	name: 'highLight'
})
export class HighLightPipe implements PipeTransform {
	constructor(private _sanitizer: DomSanitizer) {}

	public transform(initValue: string, searchText: string): any {
		if (isNullOrUndefined(initValue)) {
			return [];
		}
		if (isNullOrUndefined(searchText)) {
			return initValue;
		}
		const re: RegExp = new RegExp(searchText, 'gi');
		const subStrStartPosition: number = initValue.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase());
		const modifiedString: string = initValue.replace(
			re,
			`<span style='color: #fff;background-color:#232628'>${initValue.slice(
				subStrStartPosition,
				subStrStartPosition + searchText.length
			)}</span>`
		);
		return this._sanitizer.bypassSecurityTrustHtml(modifiedString);
	}
}
