import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { ServiceCategoryInterface, ServiceCategoryPageInterface } from '@cactussoft/services/interfaces/service-category.interface';
import { environment } from '../../../../../apps/savviva-board/src/environments/environment';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.service}/administration`;

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {
	constructor(private http: HttpClient) {}

	public getServiceCategories(filterValues?: FilterValuesInterface): Observable<ServiceCategoryPageInterface> {
		return this.http.get<ServiceCategoryPageInterface>(`${baseUrl}/categories/parents${queryBuilder(filterValues)}`);
	}

	public getAllServiceParentsCategories(): Observable<ServiceCategoryInterface[]> {
		return this.http.get<ServiceCategoryInterface[]>(`${baseUrl}/categories/parents/all`);
	}

	public getAllServiceCategories(): Observable<ServiceCategoryInterface[]> {
		return this.http.get<ServiceCategoryInterface[]>(`${baseUrl}/categories/all`);
	}

	public createCategory(category: any): Observable<any> {
		return this.http.post<any>(`${baseUrl}/categories`, category);
	}

	public editCategory(category: any, id: number): Observable<any> {
		return this.http.put<any>(`${baseUrl}/categories/parents/${id}`, category);
	}

	public getCategoryById(id: number): Observable<ServiceCategoryInterface[]> {
		return this.http.get<ServiceCategoryInterface[]>(`${baseUrl}/categories/parents/${id}`);
	}

	public changeCategoryState(id: number, state: boolean): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/categories/${id}`, { isInactive: state });
	}
}
