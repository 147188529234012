import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { ClientsService } from '@cactussoft/clients/services/clients.service';
import { AssignmentClientsDtoInterface } from '@cactussoft/clients/interfaces/assignment-client-dto.interface';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { TreeDialogInitialValuesInterface } from '@shared/components/tree/tree-dialog-initial-values.interface';
import { NewsService } from '@cactussoft/news/services/news.service';

@Component({
	selector: 'cactussoft-expandable-assign-dialog',
	templateUrl: './expandable-assign-dialog.component.html',
	styleUrls: ['./expandable-assign-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableAssignDialogComponent {
	@ViewChild('searchInput', { static: false }) public searchInput: ElementRef;
	@Output() public dialogSource: EventEmitter<any> = new EventEmitter<any>();

	public searchText: string = '';
	public toggleSearch: boolean = false;
	public treeFilterValue: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: TreeDialogInitialValuesInterface,
		private cdr: ChangeDetectorRef,
		private clientsService: ClientsService,
		private servicesService: ClientServicesService,
		private newsService: NewsService
	) {}

	public openSearch(): void {
		this.toggleSearch = true;
		this.searchInput.nativeElement.focus();
	}

	public closeDialog(): void {
		this.dialogSource.emit({ action: 'actionDialog' });
	}

	public triggerSearch(value?: boolean): void {
		this.treeFilterValue = value ? undefined : this.searchText;
		if (value) {
			this.searchText = '';
		}
	}

	public sendData(clients: AssignmentClientsDtoInterface): void {
		switch (this.data.type) {
			case 'manager-expandable':
				this.attachClientsToManager(clients).subscribe(() => {
					this.dialogSource.emit({ action: 'actionDialog' });
				});
				break;
			case 'service-expandable':
				this.attachClientsToService(clients).subscribe(() => {
					this.dialogSource.emit({ action: 'actionDialog' });
				});
				break;
			case 'news-expandable':
				this.attachClientsToNews(clients).subscribe(() => {
					this.dialogSource.emit({ action: 'actionDialog' });
				});
				break;
			default:
				this.dialogSource.emit(clients);
				break;
		}
	}

	private attachClientsToManager(clients: AssignmentClientsDtoInterface): Observable<any> {
		return this.clientsService.attachClients(clients, this.data.id);
	}

	private attachClientsToService(clients: AssignmentClientsDtoInterface): Observable<any> {
		return this.servicesService.attachClients(clients, this.data.id);
	}

	private attachClientsToNews(clients: AssignmentClientsDtoInterface): Observable<any> {
		return this.newsService.attachClients(clients, this.data.id);
	}
}
