<mat-table #table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="table">
	<ng-container *ngFor="let col of renderColsDescription; let columnIndex = index" [matColumnDef]="col.key">
		<mat-header-cell class="table__header" mat-header-cell mat-sort-header
			[ngClass]="{'table-header-cell__icon': col.config?.isIconTitle}"
			[disabled]="col.config?.isEditable || col.config?.isLogo || col.config?.isSelectable" *matHeaderCellDef
			[style.flex]="col.flexPriority">
			<ng-container *ngIf="col.config?.isIconTitle && !col.config?.isSelectable">
				<mat-icon>{{ renderColsDescription[columnIndex]["display"] }}</mat-icon>
			</ng-container>
			<ng-container *ngIf="!col.config?.isIconTitle && !col.config?.isSelectable">
				{{ renderColsDescription[columnIndex]["display"] }}
			</ng-container>
			<ng-container *ngIf="col.config?.isSelectable">
				<mat-checkbox (change)="$event ? masterToggle() : null"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
				</mat-checkbox>
			</ng-container>
		</mat-header-cell>
		<mat-cell class="table__cell" mat-cell *matCellDef="let element; let rowIndex = index"
			[style.flex]="col.flexPriority">
			<ng-container class="table__cell_default" *ngIf="!col.config; else hasConfig">
				<div class="table__cell_default">
					<span>{{element[col.key]}}</span>
				</div>
			</ng-container>
			<ng-template #hasConfig>
				<ng-container *ngIf="col.config.isSelectable">
					<mat-checkbox (click)="$event.stopPropagation()"
						(change)="$event ? changeSelection(element, $event) : null"
						[checked]="selection.isSelected(element.id)" [aria-label]="checkboxLabel(element)">
					</mat-checkbox>
				</ng-container>
				<ng-container *ngIf="col.config.isIconTitle">
					<div class="">{{ element[col.key] }}</div>
				</ng-container>
				<ng-container *ngIf="col.config.isLogo">
					<div class="table__cell_logo">
						<img class="image" *ngIf="getUrlImage(element, col)" src="{{getUrlImage(element, col)}}">
						<div class="status-icon" *ngIf="getRoleIcon(element, col)"
							[ngStyle]="{'color':getRoleIcon(element, col) === 'star' ? '#FDB12B' : '#87849a'}">
							<mat-icon>{{getRoleIcon(element, col)}}</mat-icon>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="col.config.isSubTitle">
					<div style="display: flex; flex-direction: column;">
						<div class="table__cell_subtitle_lg">
							{{getTitleValue(element, col)}}
						</div>
						<div class="table__cell_subtitle_sm">
							{{getSubTitleValue(element, col)}}
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="col.config.isStatus">
					<cactussoft-status-cell [value]="getStateValue(element, col)" [flag]="getStateFlag(element, col)">
					</cactussoft-status-cell>
				</ng-container>
				<ng-container *ngIf="col.config.isInvoicingState">
					<div class="table__cell_default">
						<span [ngStyle]='{"color": !element[col.key] ? "#888" : "#222"}'>{{element[col.key] | invoicingState}}</span>
					</div>
				</ng-container>
				<ng-container *ngIf="col.config.isBadge">
					<div (click)="$event.stopPropagation()">
						<cactussoft-badge [badgeData]="element[col.key]" (openDialog)="openDialog(element)">
						</cactussoft-badge>
					</div>
				</ng-container>
				<ng-container *ngIf="col.config.isEditable">
					<div class="table__actions" (click)="$event.stopPropagation()">
						<cactussoft-table-actions [actions]="tableActions" [element]="element"
							(clickEmitter)="onTableAction($event, element)"></cactussoft-table-actions>
					</div>
				</ng-container>
				<ng-container *ngIf="col.config.isDate">
					{{ element[col.key] | date: col.config.format }}
				</ng-container>
			</ng-template>
		</mat-cell>
	</ng-container>

	<mat-header-row mat-header-row *matHeaderRowDef="keys"></mat-header-row>
	<mat-row mat-row *matRowDef="let row; columns: keys" (click)="clickOnRow(row)"
		class="table__row example-element-row" [class.example-expanded-row]="expandedElement === row"></mat-row>
</mat-table>
<mat-paginator *ngIf='!paginatorNotExist' [length]="paginatorOptions ? paginatorOptions.totalItems : 0"
	[pageSize]="paginatorOptions ? paginatorOptions.pageSize : 0"></mat-paginator>
