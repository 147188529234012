<div class="dialog-container">
	<div class="dialog-container__header">
		<div class="title-container">
			<h2 class="title-container__title" *ngIf="data.type !== 'news-expandable'">Add new client for <span
				class="label-primary">{{data.type === 'manager-expandable' ? data.firstName : data.name}} {{ data.lastName}}</span></h2>
			<h2 class="title-container__title" *ngIf="data.type === 'news-expandable'"><span
				class="label-primary">{{data.name}}</span></h2>
		</div>
		<div class="actions-container">
			<div class="actions-container__search">
				<button mat-icon-button class="actions-container__button" (click)="openSearch()">
					<mat-icon>search</mat-icon>
				</button>
				<div class="actions-container__search_toggle" [class.active]="toggleSearch">
					<mat-form-field style="width: 100%;">
						<mat-icon matPrefix>search</mat-icon>
						<input matInput #searchInput class="search-control" value=""
							   [(ngModel)]="searchText" (keydown.enter)="triggerSearch()">
						<button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="triggerSearch(true)">
							<mat-icon>close</mat-icon>
						</button>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div class="dialog-container__tree">
		<cactussoft-tree-checklist [dialogData]="data" (selectedClientsSource)="sendData($event)"
								   [treeFilterValue]='treeFilterValue'
								   (closeDialogSource)="closeDialog()"></cactussoft-tree-checklist>
	</div>
</div>
