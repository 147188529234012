import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';
import { queryBuilder } from '@shared/tools/query-builder';

import { CreateClientDtoInterface } from '../interfaces/create-client-dto.interface';
import { ClientDtoInterface } from '@cactussoft/clients/interfaces/client-dto.interface';
import { ClientPageDtoInterface } from '@cactussoft/clients/interfaces/client-page-dto.interface';
import { AssignmentClientsDtoInterface } from '@cactussoft/clients/interfaces/assignment-client-dto.interface';
import { environment } from '../../../../../apps/savviva-board/src/environments/environment';

const baseUrl: string = `${JSON.parse(localStorage.getItem('env'))?.client}/clients`;
const identityUrl: string = `${JSON.parse(localStorage.getItem('env'))?.identity}`;
@Injectable({
	providedIn: 'root'
})
export class ClientsService {
	constructor(private http: HttpClient) {}

	public getClients(filterValues?: FilterValuesInterface): Observable<ClientPageDtoInterface> {
		return this.http.get<ClientPageDtoInterface>(`${baseUrl}${queryBuilder(filterValues)}`);
	}

	public getAllClients(): Observable<ClientDtoInterface[]> {
		return this.http.get<ClientDtoInterface[]>(`${baseUrl}/all`);
	}

	public createClient(client: CreateClientDtoInterface): Observable<any> {
		return this.http.post(`${baseUrl}`, client);
	}

	public getClientById(id: number): Observable<ClientDtoInterface> {
		return this.http.get<ClientDtoInterface>(`${baseUrl}/${id}`);
	}

	public editClient(client: CreateClientDtoInterface, id: number): Observable<any> {
		return this.http.put(`${baseUrl}/${id}`, client);
	}

	public attachClients(clients: AssignmentClientsDtoInterface, id: number): Observable<any> {
		return this.http.patch(`${identityUrl}/admins/attachment/${id}`, clients);
	}

	public changeClientState(id: number, state: boolean): Observable<any> {
		return this.http.patch<any>(`${baseUrl}/${id}`, { isInactive: state });
	}
}
