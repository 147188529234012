import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { merge } from 'rxjs';

import { BasicAssignDialogComponent } from '@shared/components/basic-assign-dialog/basic-assign-dialog.component';
import { ExpandableAssignDialogComponent } from '@shared/components/expandable-assign-dialog/expandable-assign-dialog.component';
import { PaginatorInterface } from '@shared/components/table/interfaces/paginator.interface';
import { FilterValuesInterface } from '@shared/components/table/interfaces/filter-values.interface';

import { ColumnDataInterface } from './interfaces/column-data.interface';
import { TableActionsInterface } from '@shared/components/table/interfaces/table-actions.interface';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Component({
	selector: 'cactussoft-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements AfterViewInit, OnChanges {
	@Input() public tableData: any;
	@Input() public tableActions: TableActionsInterface[];
	@Input() public tableColsDescription: ColumnDataInterface[];
	@Input() public paginatorOptions: PaginatorInterface;
	@Input() public isEditable: boolean;
	@Input() public isSelectable: boolean;
	@Input() public isDisableDialogs: boolean;
	@Input() public typeOfAssignDialog: string;
	@Input() public paginatorNotExist: boolean;
	@Input() public updateSelection: any;

	@Output() public rowActionSource: EventEmitter<any> = new EventEmitter<any>();
	@Output() public rowSelectionSource: EventEmitter<any> = new EventEmitter<any>();
	@Output() public selectionSource: EventEmitter<any> = new EventEmitter<any>();
	@Output() public dataPortionSource: EventEmitter<FilterValuesInterface> = new EventEmitter<FilterValuesInterface>();
	@ViewChild(MatTable) public table: MatTable<any>;
	@ViewChild(MatPaginator) public paginator: MatPaginator;
	@ViewChild(MatSort) public sort: MatSort;

	public renderTableData: any;
	public renderColsDescription: ColumnDataInterface[];
	public dialogRef: MatDialogRef<BasicAssignDialogComponent | ExpandableAssignDialogComponent>;
	public selection: SelectionModel<any> = new SelectionModel<any>(true, []);
	public expandedElement: any | null;

	public dataSource: any = new MatTableDataSource();

	constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog) {}

	public openDialog(item: any): void {
		if (!this.isDisableDialogs) {
			switch (this.typeOfAssignDialog) {
				case 'default': {
					this.dialogRef = this.dialog.open(BasicAssignDialogComponent, {
						data: {
							name: item.name.title,
							id: item.id,
							buildings: item.buildings,
							servicesCount: item.servicesCount
						},
						width: '1200px'
					});
					break;
				}
				case 'service-expandable':
				case 'news-expandable': {
					this.dialogRef = this.dialog.open(ExpandableAssignDialogComponent, {
						data: {
							type: this.typeOfAssignDialog,
							name: item.name,
							id: item.id,
							pageSize: 5
						},
						width: '1200px'
					});
					break;
				}
				case 'manager-expandable': {
					this.dialogRef = this.dialog.open(ExpandableAssignDialogComponent, {
						data: {
							type: this.typeOfAssignDialog,
							firstName: item.firstName,
							lastName: item.lastName,
							id: item.id,
							pageSize: 5
						},
						width: '1200px'
					});
					break;
				}
			}
			this.dialogRef.componentInstance.dialogSource.subscribe(() => {
				this.dataPortionSource.emit({
					sortField: this.dataSource.sort.active,
					sortDirection: this.dataSource.sort.direction,
					nextPageIndex: this.paginator.pageIndex
				});
				this.dialogRef.close();
			});
		}
	}

	public ngAfterViewInit(): void {
		this.renderColsDescription = [...this.tableColsDescription];
		this.dataSource.sort = this.sort;
		this.paginatorNotExist
			? this.dataSource.sort.sortChange.subscribe(() => {
					this.dataPortionSource.emit({
						sortField: this.dataSource.sort.active,
						sortDirection: this.dataSource.sort.direction
					});
			  })
			: merge(this.dataSource.sort.sortChange, this.paginator.page).subscribe(() => {
					this.dataPortionSource.emit({
						sortField: this.dataSource.sort.active,
						sortDirection: this.dataSource.sort.direction,
						nextPageIndex: this.paginator.pageIndex
					});
			  });
		this.cdr.detectChanges();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['updateSelection']) {
			const initialSelection: any = [];
			this.dataSource = new MatTableDataSource(this.tableData);
			this.dataSource.sort = this.sort;
			this.dataSource.data.forEach((row: any) => {
				if (row.isSelectedDefault) {
					initialSelection.push(row.id);
				}
			});
			this.selection = new SelectionModel<any>(true, initialSelection);
		} else if (!changes['tableData']?.firstChange) {
			this.dataSource = new MatTableDataSource(this.tableData);
			this.dataSource.sort = this.sort;
		}
		this.cdr.detectChanges();
	}

	get keys(): string[] {
		if (Boolean(this.renderColsDescription)) {
			return this.renderColsDescription.map(({ key }: any) => key);
		} else {
			return [];
		}
	}

	public getStateFlag(elt: any, column: ColumnDataInterface): boolean {
		return elt[column.key];
	}

	public getStateValue(elt: any, column: ColumnDataInterface): string {
		return column.config.values[`${elt[column.key]}`];
	}

	public getTitleValue(elt: any, column: ColumnDataInterface): string | boolean | string[] {
		return this.getFieldValueByIndex(elt, column, 0);
	}

	public getSubTitleValue(elt: any, column: ColumnDataInterface): string | boolean | string[] {
		return this.getFieldValueByIndex(elt, column, 1);
	}

	// public isDropdownValueSelected(elt: any, column: ColumnDataInterface): string | boolean | string[] {
	// 	return this.isValueDefined(this.getFieldValueByIndex(elt, column, 0));
	// }

	public getDropdownValue(elt: any, column: ColumnDataInterface): string | boolean | string[] {
		return this.getFieldValueByIndex(elt, column, 0);
	}

	// public updateTableView(test: any, fieldName: string, rowIndex: number): void {
	// 	this.renderTableData[rowIndex][fieldName].items = test;
	// 	this.cdr.detectChanges();
	// }

	// public isArrayLengthMore(value: any): boolean {
	// 	return Boolean(this.numberOfItemsToShow) ? value.length > this.numberOfItemsToShow : value.length > 1;
	// }

	// public getChipsItems(elt: any, column: ColumnDataInterface): string[] {
	// 	const list: any = this.getDropdownValue(elt, column);
	// 	return this.numberOfItemsToShow - 1 > list.length ? list : list.slice(0, this.numberOfItemsToShow);
	// }

	// public getBadgeItems(elt: any, column: ColumnDataInterface): string[] {
	// 	const list: any = this.getDropdownValue(elt, column);
	// 	return list;
	// 	// return this.numberOfItemsToShow - 1 > list.length ? [] : list.slice(this.numberOfItemsToShow, list.length);
	// }

	public getUrlImage(elt: any, column: ColumnDataInterface): any {
		return this.getFieldValueByIndex(elt, column, 0);
	}

	public getRoleIcon(elt: any, column: ColumnDataInterface): any {
		switch (this.getFieldValueByIndex(elt, column, 1)) {
			case 'Client Manager':
				return 'person_pin';
			case 'Admin':
				return 'star';
			default:
				return null;
		}
	}

	public onTableAction(eventName: string, element: any): void {
		this.rowActionSource.emit({ eventName, element });
	}

	public clickOnRow(row: any): void {
		// if (this.isExpandable) {
		// 	this.expandedElement = this.expandedElement === row ? null : row;
		// }
		// if (this.isDetailView) {
		// 	this.router.navigate(['manager-details/', row.id], {relativeTo: this.route});
		// }
		this.rowSelectionSource.emit(row);
	}

	public isAllSelected(): boolean {
		const numSelected: number = this.selection.selected.length;
		const numRows: number = this.dataSource.data?.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	public masterToggle(): void {
		this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row: any) => this.selection.select(row.id));
		this.emitSelection();
	}

	/** The label for the checkbox on the passed row */
	public checkboxLabel(row?: any): string {
		if (!row) {
			return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
	}

	public changeSelection(element: any, event: any, building?: any): void {
		this.selection.toggle(element.id);
		this.emitSelection(element, building);
	}

	private emitSelection(element?: any, building?: any): void {
		this.selectionSource.emit({
			selection: this.selection.selected,
			selectedBuilding: Boolean(building) ? building : null,
			currentClientId: Boolean(element) ? element.id : null
		});
	}

	private getFieldValueByIndex(elt: any, column: ColumnDataInterface, index: number): string | boolean | string[] {
		const temp: any = elt[column.key];
		return temp[column.config.fields[index]];
	}
}
