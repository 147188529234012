import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@shared/services/auth/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';
import { isNullOrUndefined } from '@shared/tools/is-undefined-null';

@Component({
	selector: 'cactussoft-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {
	public formGroup: UntypedFormGroup;
	public isHiddenPassword: boolean = true;
	public email: string = '';
	public isAdmin: boolean;
	private code: string;

	constructor(
		private authService: AuthService,
		private formBuilder: UntypedFormBuilder,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private _activatedRoute: ActivatedRoute,
		private snackBarService: SnackBarService
	) {}

	public ngOnInit(): void {
		this._activatedRoute.queryParams.subscribe((params: Params) => {
			this.email = params.email.replace(' ', '+');
			this.code = params.code;
			this.isAdmin = params.isAdmin;
			this.createDefaultForm();
		});
	}

	public resetPassword(): void {
		if (this.isPasswordsMatch()) {
			this.authService.resetPassword(this.email, this.code, this.formGroup.controls.password.value).subscribe(
				() => {
					this.snackBarService.showSnackBar('Password was reset');
					if (this.isAdmin) {
						this.router.navigate(['auth']);
					}
				},
				() => {
					this.snackBarService.showSnackBar('Password reset was failed');
				}
			);
		}
	}

	private createDefaultForm(): void {
		this.formGroup = this.formBuilder.group({
			password: new UntypedFormControl(null),
			confirmPassword: new UntypedFormControl(null)
		});
	}

	private isPasswordsMatch(): boolean {
		const err: any =
			this.formGroup.controls.password.value === this.formGroup.controls.confirmPassword.value ? null : { passwordMismatch: true };
		this.formGroup.controls.confirmPassword.setErrors(err);
		return isNullOrUndefined(err);
	}
}
