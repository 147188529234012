<div style='display: flex;' cdkDropListGroup>
	<div class='create-service-container'>
		<span class='create-service-container__header-label'>{{getHeaderLabel()}}</span>
		<form [formGroup]='newServiceForm' *ngIf='newServiceForm'>
			<div class='about-container form' *ngIf='typeOfInstance === "service" || !typeOfInstance'>
				<span class='form__label'>General info</span>
				<div style='display: flex; justify-content: space-between;'>
					<cactussoft-uploader [uploaderType]='uploaderType' [initialImage]='initialImage'
						(uploadSource)='uploadServicePicture($event)'></cactussoft-uploader>
				</div>
				<div class='form__row auto-fit'>
					<div class='form__row-block' [formGroup]='namesFormGroup'>
						<mat-form-field class='form__row-input' appearance='fill'>
							<mat-label> Name
							</mat-label>
							<input matInput formControlName='English' required>
							<mat-error
								*ngIf='namesFormGroup.controls.English.touched && namesFormGroup.controls.English.invalid'>
								<span *ngIf='namesFormGroup.controls.English.errors.required'>Required field</span>
								<span *ngIf='namesFormGroup.controls.English.errors.minlength'>
									Min length - 3 char</span>
								<span *ngIf='namesFormGroup.controls.English.errors.maxlength'>
									Max length - 60 char</span>
							</mat-error>
						</mat-form-field>
						<mat-icon class='form__language-icon'
							(click)='openMultiLanguageDialog(namesFormGroup,"input","Name")'>
							language
						</mat-icon>
					</div>
					<div class='form__row-block'>
						<mat-form-field class='form__row-input' appearance='fill'>
							<mat-label>Select currency</mat-label>
							<mat-select formControlName='currency' required>
								<mat-option *ngFor='let currencyType of currencyTypes; let i = index' [value]='i'>
									{{currencyType}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div *ngFor='let categoryFormGroup of categoriesControls;let i = index;'>
					<div class='form__row auto-fit' [formGroup]='categoryFormGroup'>
						<mat-form-field appearance='fill'>
							<mat-label>Category</mat-label>
							<mat-select formControlName='category' required (selectionChange)='categorySelected(i)'>
								<mat-option *ngFor='let category of initialCategories' [value]=category.id
									[disabled]='category.disabled'>{{category.name}}</mat-option>
							</mat-select>
							<mat-error
								*ngIf='categoryFormGroup.value.category.touched && categoryFormGroup.value.category.invalid'>
								<span *ngIf='categoryFormGroup.value.category.errors.required'>Required field</span>
							</mat-error>
						</mat-form-field>
						<div class='form-field-container-with-delete'>
							<mat-form-field appearance='fill'>
								<mat-label>Sub Category (optional)</mat-label>
								<mat-select formControlName='subCategory' multiple [compareWith]='compareWith'
									[disabled]='!categoryFormGroup.value.subCategoryList'>
									<mat-option *ngFor='let subCategory of categoryFormGroup.value.subCategoryList'
										[value]=subCategory.id>{{subCategory.name}}</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-icon *ngIf='i !== 0' (click)='removeCategoryFormControl(i)'>delete</mat-icon>
						</div>
					</div>
				</div>
				<div *ngIf='categoriesControls.length < initialCategories.length' (click)='addCategoryFormControl()'
					class='add-variant-label'>+ Add Category
				</div>
				<span class='required-label'>* Required fields</span>
				<div class='service-checkbox-container'>
					<h4>
						Choose whether any of these functionalities are displayed in the service page
					</h4>
					<div class='service-checkbox-container__checkboxes'>
						<mat-checkbox formControlName='hasDelivery'>Delivery</mat-checkbox>
						<mat-checkbox formControlName='hasInvoicing'>Invoicing</mat-checkbox>
						<mat-checkbox formControlName='hasSubmit'>Submit</mat-checkbox>
					</div>
				</div>
				<div *ngIf="newServiceForm.controls['hasDelivery'].value">
					<cactussoft-delivery-price [service]="service"></cactussoft-delivery-price>
				</div>
			</div>
			<div cdkDropList [cdkDropListData]='serviceComponentsList' class='components-form'
				(cdkDropListDropped)='drop($event)'>
				<div class='components-form-description'>
					<h3>Products items</h3>
					<span class='components-form-description__label'>
						Please use drag&drop for create service form or select template
					</span>
					<div class='form__row auto-fit'>
						<mat-form-field appearance='fill' *ngIf='typeOfInstance !== "template"'>
							<mat-label>Template (optional)</mat-label>
							<mat-select (selectionChange)='selectTemplate($event.value?.id)' [value]='selectedTemplate'>
								<mat-option [value]='null'> No template </mat-option>
								<mat-option *ngFor='let template of allTemplates' [value]='template'>
									{{template.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div *ngFor='let component of serviceComponentsList'>
					<div [ngSwitch]='component.id'>
						<cactussoft-input-with-icon-builder
							*ngSwitchCase="['description', 'alert','hidden_text', 'input','toggle', 'budget_input'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [componentName]='component.name'
							[placeholder]='component.placeholder' [initialValues]='component.initialValues'
							scrollingContainer='mat-sidenav-content'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-input-with-icon-builder>
						<cactussoft-input-with-variants
							*ngSwitchCase="['one_selection','multi_selection'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [componentName]='component.name'
							[placeholder]='component.placeholder' [initialValues]='component.initialValues'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-input-with-variants>
						<cactussoft-picker-builder
							*ngSwitchCase="['date_picker','time_picker'].includes(component.id) ? component.id : ''"
							[initialValues]='component.initialValues' [placeholder]='component.placeholder'
							[iconName]='component.icon'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-picker-builder>
						<cactussoft-gallery-builder
							*ngSwitchCase="['gallery'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [initialValues]='component.initialValues'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>>
						</cactussoft-gallery-builder>
						<cactussoft-image-loader-builder
							*ngSwitchCase="['picture_product_list'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [initialValues]='component.initialValues'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-image-loader-builder>
						<cactussoft-labels-builder
							*ngSwitchCase="['labels_picker'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [placeholder]='component.placeholder'
							[initialValues]='component.initialValues'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-labels-builder>
						<cactussoft-two-column-product-list
							*ngSwitchCase="['two_column_product_list'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [placeholder]='component.placeholder'
							[initialValues]='component.initialValues'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-two-column-product-list>
						<cactussoft-product-list-builder
							*ngSwitchCase="['product_list'].includes(component.id) ? component.id : ''"
							[iconName]='component.icon' [placeholder]='component.placeholder'
							[initialValues]='component.initialValues'
							(removeComponentSource)='removeFormControl(component.controlName)'
							(changeValuesSource)='updateFormControlValue($event,component.controlName)'>
						</cactussoft-product-list-builder>
					</div>
				</div>
			</div>
			<div class='form__buttons-services'>
				<div style='display: flex;'>
					<div class='button-item secondary' (click)='cancel()'>
						Cancel
					</div>
					<div *ngIf='typeOfInstance === "service" || !typeOfInstance' class='button-item primary'
						[ngClass]='{disabled: !(serviceComponentsList.length > 0)}'
						(click)='instanceId ? editService() : createService()'>
						{{instanceId ? 'Save' : 'Create'}}
					</div>
					<button *ngIf='instanceId' (click)="removeService()" mat-button>
						<img src="/assets/icons/delete-icon.svg" />
						<span class="delete-service-label">Delete</span>
					</button>
					<div *ngIf='typeOfInstance === "template"' class='button-item primary' (click)='editTemplate()'>
						Edit
					</div>
				</div>
				<div *ngIf='typeOfInstance !== "template"' class='save-as-template-button'
					(click)='openSaveAsTemplateDialog()'>Save as template
				</div>
			</div>
		</form>
	</div>
	<div class='drag-container'>
		<div class='fixed-container'>
			<h2>Add element</h2>
			<div class='drag-container__category' *ngFor='let key of keysOfCategories' cdkDropList
				[cdkDropListData]='listOfComponents[key]' [cdkDropListEnterPredicate]='noReturnPredicate'
				(cdkDropListDropped)='drop($event)'>
				<span class='drag-container__category_name'>{{categoriesLabels[key]}}</span>
				<div class='drag-container__category_item' *ngFor='let component of listOfComponents[key]' cdkDrag>
					<div class='draggable-icon-wrapper'>
						<mat-icon class='component-icon'>drag_indicator</mat-icon>
					</div>
					<div class='component-icon-wrapper'>
						<ng-container *ngIf="component.id !== 'budget_input'; else budgetInputIcon">
							<mat-icon class='component-icon'>{{component.icon}}</mat-icon>
						</ng-container>
					</div>
					<div class='component-description'>
						<span class='component-description__title'>{{component.name}}</span>
						<span class='component-description__text'>{{component.description}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #budgetInputIcon>
	<img class='component-icon' src='assets/icons/budget_icon.svg' />
</ng-template>
