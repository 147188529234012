import { Component, EventEmitter, Inject, Output, ViewEncapsulation } from '@angular/core';
import { ClientServicesService } from '@cactussoft/services/services/client-services.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@shared/services/snack-bar/snack-bar.service';

@Component({
	selector: 'cactussoft-delete-service',
	templateUrl: 'delete.service.component.html',
	styleUrls: ['delete.service.component.scss']
})
export class DeleteServiceComponent {
	@Output() public dialogSource: EventEmitter<boolean> = new EventEmitter<boolean>();

	public canDelete: boolean = true;

	constructor(
		private clientServicesService: ClientServicesService,
		private snackBarService: SnackBarService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	public onDeleteClick(): void {
		this.clientServicesService.getRemovableServiceState(this.data.id).subscribe((clientsCount: number) => {
			this.canDelete = clientsCount === 0;
			if (this.canDelete) {
				this.clientServicesService.removeService(this.data.id).subscribe();
				this.dialogSource.emit(true);
			} else {
				this.snackBarService.showSnackBar('This service cannot be deleted');
			}
		});
	}

	public onCloseClick(): void {
		this.dialogSource.emit(false);
	}
}
